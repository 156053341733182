
var Animate = {

    el(el, obj){
        var {delay, time, stagger, animation, lib } = obj;

        // var lib = lib;

        Animate.hide(el);

        $(el).removeClass(animation).css({
            'animation-duration': time +'ms',
            '-webkit-animation-duration': time +'ms'
        });
        setTimeout(function () {
            Animate.show(el);
            $(el).addClass(lib +' '+ animation);
        }, delay );

    },
    sequence(el, obj){
        var {delay, time, stagger, animation, lib } = obj;

        var lib = lib || 'magictime';


        $(el).removeClass(animation).css({
            'animation-duration': time +'ms',
            '-webkit-animation-duration': time +'ms'
        }).each(function (i,el) {
    		setTimeout(function () {
    			$(el).removeClass('hide').addClass(lib +' '+ animation);
    		}, delay + i * stagger);
    	});
    },
    hide(el){
        $(el).css({ opacity: 0 });
    },
    show(el){
        $(el).css({ opacity: 1 }).removeClass('hide');
    }

};

$(function() {
    // if( $('.kids-promo .text > *').length ){
    //     Animate.sequence( $('.kids-promo .text > *'), { delay:222, time: 444, stagger:222, animation:'spaceInUp', lib:'magictime' });
    //
    // }

    // KIDS PROMO
    (function() {
        var button = $('.kids-promo .button');
        if( $('.kids-promo .button').length ){
            Animate.el( button, { delay:1, time: 444,  animation:'spaceInUp-target' });
        }
    })();


    if( $('.slick-offer').length ){
        var waypoint = new Waypoint({
            element: $('.slick-offer'),
            offset: '100%',
            handler: function(direction) {
                if( direction === 'down' )
                    Animate.sequence( $('.slick-offer .slick-dots li'), { delay:111, time: 444, stagger:111, animation:'zoomInLeft', lib:'animated' });

                console.log(direction);
            }
        });
    }


    if( $('.kitty-svg').length ){
        Animate.hide(  $('.kitty-svg') );
        var waypoint = new Waypoint({
            element: $('.kitty-svg'),
            offset: 'bottom-in-view',
            handler: function(direction) {
                if( direction === 'down' ){
                    Animate.show( $('.kitty-svg') );
                    Animate.el( $('.kitty-svg img'), { delay:11, time: 1110, stagger:111, animation:'bounceInUp-target' });
                }


            }
        });
    }

    // KADRA
    // Animate.hide( $('.employee') );
    // if( $('.employee').length ){
    //     var waypoint = new Waypoint({
    //         element: $('.employees'),
    //         offset: '90%',
    //         handler: function(direction) {
    //             if( direction === 'down' ){
    //                 Animate.show( $('.employee') );
    //                 Animate.sequence( $('.employee'), { delay:0, time: 333, stagger:55, animation:'slideInDownOp-target' });
    //             }
    //
    //
    //         }
    //     });
    // }

    // SQUARE GALLERY
    // (function(){
    //     var container = $('.square-gallery-container')
    //     var items = $('.square-gallery-item')
    //     Animate.hide(items);
    //     if( items.length ){
    //         var waypoint = new Waypoint({
    //             element: container,
    //             offset: '90%',
    //             handler: function(direction) {
    //                 if( direction === 'down' ){
    //                     Animate.show(items);
    //                     Animate.sequence( items, { delay:0, time: 999, stagger:111, animation:'plum100p-target' });
    //                 }
    //
    //
    //             }
    //         });
    //     }
    // })();

    //  GALLERY
    // (function(){
    //     var container = $('.gallery-container');
    //     var items = $('.gallery-item');
    //     Animate.hide(items);
    //     if( items.length ){
    //         var waypoint = new Waypoint({
    //             element: container,
    //             offset: '80%',
    //             handler: function(direction) {
    //                 if( direction === 'down' ){
    //                     Animate.show(items);
    //                     Animate.sequence( items, { delay:0, time: 333, stagger:50, animation:'slideInRight-target' });
    //                 }
    //
    //
    //             }
    //         });
    //     }
    // })();

    //  FROGS & BUGS
    (function(){
        var container = $('.frog-butterfly-ladybug');
        var items = $('.frog-butterfly-ladybug > div');
        Animate.hide(items);
        if( items.length ){
            var waypoint = new Waypoint({
                element: container,
                offset: '90%',
                handler: function(direction) {
                    if( direction === 'down' ){
                        Animate.show(items);
                        Animate.sequence( items, { delay:100, time: 1333, stagger:250, animation:'plum100p-target' });
                    }


                }
            });
        }
    })();

});
